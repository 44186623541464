<script>
    import api from "./api";
    import {signIn as setUserSignedIn} from "./store";

    let email = "", password = "", error = "";
    let working = false;

    function handleEnter(e) {
        if (!working && e.key === "Enter") {
            signIn();
        }
    }

    async function signIn() {
        error = "";
        if (!email) {
            error = "Please enter your e-mail address"
            return;
        }
        if (!/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(email.trim())) {
            error = "Please enter a valid e-mail address";
            return;
        }
        if (!password) {
            error = "Please enter your password"
            return;
        }
        working = true;
        const result = await api.signIn(email, password);
        working = false;
        if (result.error) {
            error = result.error;
            return;
        }
        const user = {
            token: result.token,
            profile: result.profile,
            expires: Date.now() + result.expiresIn * 1000
        };
        setUserSignedIn(user);
    }
</script>
<div class="container">
    <h1>Welcome</h1>
    <p>
        Welcome to the Believe Finance Customer Portal. Please enter your e-mail
        address and password to sign in:
    </p>
    <div class="form">
        <label for="email">E-mail Address:</label>
        <input id="email" type="email" bind:value={email} on:keyup={handleEnter}/>
        <label for="password">Password:</label>
        <input id="password" type="password" bind:value={password} on:keyup={handleEnter}/>
        <button disabled={working} on:click={signIn}>{ working ? "Please Wait..." : "Sign In" }</button>
        <span class="error">{error}</span>
    </div>
    <p>
        <a href="/reset">Forgot password?</a>
    </p>
</div>