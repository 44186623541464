import {writable} from "svelte/store";

const storedUser = JSON.parse(sessionStorage.getItem("user"));
const sessionUser = storedUser ? {...storedUser, signedIn: true} : {signedIn: false};
export const user = writable(sessionUser);

export function signIn(u) {
    sessionStorage.setItem("user", JSON.stringify(u));
    user.set({...u, signedIn: true});
}

export function signOut(u) {
    sessionStorage.removeItem("user");
    user.set({signedIn: false});
}