<script>
    import {onMount} from "svelte";
    import {router, Route} from "tinro";
    import {user, signOut} from "./store";
    import Protected from "./Protected.svelte";
    import Nav from "./Nav.svelte";
    import Documents from "./Documents.svelte";
    import Upload from "./Upload.svelte";
    import ESign from "./ESign.svelte";
    import Reset from "./Reset.svelte";
    import Invitation from "./Invitation.svelte";
    import Preview from "./Preview.svelte";
    import NotFound from "./NotFound.svelte";
    import ESignComplete from "./ESignComplete.svelte";

    onMount(() => {
        router.subscribe(() => {
            window.scrollTo(0, 0);
        });
    })
</script>
<header>
    <div class="container">
        <img src="/logo.png" alt="Believe Finance"/>
        {#if $user.signedIn}
            <div>
                <p>
                    <strong>{$user.profile.name}</strong> (<a href="." on:click|preventDefault={signOut}>Sign Out</a>)
                </p>
            </div>
        {/if}
    </div>
    {#if $user.signedIn}
        <Nav/>
    {/if}
</header>

<div class="content" class:signed-in={$user.signedIn}>
    <Route>
        <Route path="/">
            <Protected>
                <Documents/>
            </Protected>
        </Route>
        <Route path="/upload">
            <Protected>
                <Upload/>
            </Protected>
        </Route>
        <Route path="/e-sign/complete">
            <ESignComplete/>
        </Route>
        <Route path="/e-sign/:code1/:code2" let:params>
            <ESign documentCode={params.code1} signerCode={params.code2}/>
        </Route>
        <Route path="/reset">
            <Reset/>
        </Route>
        <Route path="/invitation/:code" let:params>
            <Invitation code={params.code}/>
        </Route>
        <Route path="/preview">
            <Preview/>
        </Route>
        <Route fallback>
            <NotFound/>
        </Route>
    </Route>
</div>

<style>
    :global(html), :global(body) {
        position: relative;
        width: 100%;
        height: 100%;
    }

    :global(body) {
        color: #2c3e50;
        background-color: #fafafa;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    }

    :global(.container) {
        box-sizing: border-box;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 1em;
    }

    :global(.form) {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 1em;
        align-items: center;
    }

    :global(.form input) {
        padding: 0.5em;
        border: 1px solid #cccccc;
        outline: none;
        max-width: 320px;
        font-size: 1em;
        margin: 0;
        box-sizing: border-box;
    }

    :global(.form label) {
        font-weight: bold;
    }

    :global(button) {
        background-image: linear-gradient(to bottom, #4bbcd0 0%, #348f96 100%);
        color: #ffffff;
        padding: 1em;
        font-size: 0.8em;
        font-weight: bold;
        border: 1px solid #348f96;
        cursor: pointer;
        outline: none;
        border-radius: 0.4em;
    }

    :global(button:hover) {
        background-image: linear-gradient(to bottom, #5ce7ff 0%, #47c3cc 100%);
    }

    :global(.form .error) {
        color: red;
    }

    :global(a, a:visited, a:active) {
        color: hsl(190, 63%, 40%);
    }

    header {
        background-color: #ffffff;
        border-bottom: 1px solid #cccccc;
        position: relative;
        z-index: 10;
    }

    header > .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em;
    }

    header > .container > div {
        text-align: right;
    }

    header > .container > div > h3 {
        margin: 0;
    }

    header > .container > div > p {
        margin: 0;
    }

    header img {
        max-height: 100px;
    }

    @media screen and (max-width: 767px) {
        :global(.form) {
            grid-template-columns: 100%;
        }

        :global(.form input) {
            max-width: none;
        }

        header {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 99;
        }

        header > .container {
            flex-direction: column;
        }

        header > .container > div {
            text-align: center;
        }

        header img {
            max-height: 50px;
        }

        .content {
            margin-top: 6em;
        }

        .content.signed-in {
            margin-top: 11em;
        }
    }
</style>
