<script>
    import {router} from "tinro";
    import api from "./api";
    import Document from "./Document.svelte";

    let working = false;
    let esignUrl = null;

    // eversign events
    window.addEventListener("message", e => {
        if (e.data === "event_loaded") {
        } else if (e.data === "event_signed") {
            esignUrl = null;
            router.goto("/e-sign/complete?event=signing_complete");
        } else if (e.data === "event_declined") {
            esignUrl = null;
        } else if (e.data === "event_error") {
            esignUrl = null;
        }
        working = false;
    });

    async function eSign(packId) {
        if (working) return;
        working = true;
        const result = await api.proceedToSign(packId);
        if (result) {
            if (result.redirect) {
                esignUrl = result.redirect;
                return;
            } else {
                router.goto("/e-sign/complete?event=continue_email");
            }
        }
        working = false;
    }
</script>

{#if esignUrl}
    <div class="esign">
        <iframe title="E-Signatures" src={esignUrl} frameborder="0"></iframe>
    </div>
{:else}
    <div class="container">
        <h1>Loan Documents</h1>
        <p>
            Below you will find any documents we have sent you related to your loan. Some documents requiring a
            signature can be signed electronically, allowing us to progress with your case faster. Look out for these
            documents in the <strong>Awaiting Signature</strong> section below.
        </p>
        <p>
            If you need to send us a document from your device such as a photo you've taken or a document you've
            scanned, you can do so on the
            <a href="/upload">upload page</a>.
        </p>
        <h2>Awaiting Signature</h2>
        {#await api.pendingESigns()}
            <p>Loading...</p>
        {:then eSigns}
            {#if eSigns.length}
                <div class="packs">
                    {#each eSigns as pack}
                        <div class="pack">
                            <svg xmlns="http://www.w3.org/2000/svg" width="2em" viewBox="0 0 20 20" fill="#ccc">
                                <path d="M0 18h20v1H0zm-.003-6.155l1.06-1.06 4.363 4.362-1.06 1.06z"/>
                                <path d="M.004 15.147l4.363-4.363 1.06 1.061-4.362 4.363zM17 5c0 9-11 9-11 9v-1.5s8 .5 9.5-6.5C16 4 15 2.5 14 2.5S11 4 10.75 10c-.08 2 .75 4.5 3.25 4.5 1.5 0 2-1 3.5-1a2.07 2.07 0 0 1 2.25 2.5h-1.5s.13-1-.5-1C16 15 16 16 14 16c0 0-4.75 0-4.75-6S12 1 14 1c.5 0 3 0 3 4z"/>
                            </svg>
                            <div>
                                <h3>{pack.title}</h3>
                                <p>Ready for Signature</p>
                                <p>
                                    <button on:click={() => eSign(pack.id)}>Proceed to Sign</button>
                                </p>
                            </div>
                        </div>
                    {/each}
                </div>
            {:else}
                <p>You currently have no documents awaiting your signature.</p>
            {/if}
        {/await}
        <h2>More Documents</h2>
        {#await api.documents(true)}
            <p>Loading...</p>
        {:then docs}
            {#if docs.length}
                <div class="documents">
                    {#each docs as doc, index (`${doc.fileName}_${index}`)}
                        <Document doc={doc}/>
                    {/each}
                </div>
            {:else}
                <p>We haven't sent you any further documents yet.</p>
            {/if}
        {/await}
    </div>
{/if}

<div class="working" class:show={working}>
    <svg
            class="loading"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            height="4em"
            width="4em"
    >
        <circle
                cx="50"
                cy="50"
                r="40"
                stroke="#2691a6"
                stroke-width="10"
                fill="none"
                stroke-dasharray="226.194 226.194"
        />
    </svg>
    <h2>Please wait...</h2>
</div>

<style>
    .documents, .packs {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1em;
    }

    .working {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.9);
        z-index: 100;
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .working.show {
        display: flex;
    }

    @keyframes rotating {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .working > svg {
        animation: rotating 2s linear infinite;
    }

    div.esign {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 110;
    }

    div.esign > iframe {
        width: 100%;
        height: 100%;
    }

    .pack {
        display: flex;
        background-color: #ffffff;
        padding: 1em;
        border: 1px solid #cccccc;
        text-decoration: none;
        color: #2c3e50;
        align-items: center;
    }

    .pack > svg:first-child {
        margin-right: 1em;
    }

    .pack > div {
        flex: 1;
    }

    .pack > div > :global(h3) {
        margin: 0;
    }

    .pack > div > :global(p) {
        font-size: 0.8em;
        color: #666666;
        margin: 0.5em 0;
    }

    .pack > div > :global(*:last-child) {
        margin-bottom: 0;
    }

    @media screen and (max-width: 1236px) {
        .documents, .packs {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media screen and (max-width: 767px) {
        .documents, .packs {
            grid-template-columns: 1fr;
        }
    }
</style>