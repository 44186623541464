<script>
    import { onMount } from "svelte";
    import { router } from "tinro";
    import { signIn } from "./store";

    onMount(() => {
        const token = location.hash.slice(1);
        console.log(token);
        const user = {
            token: token,
            profile: { name: "Preview "}
        };
        signIn(user);
        router.goto("/");
    })
</script>