import {user, signOut} from "./store";

let token = null;
let customerName = null;
user.subscribe(user => {
    if (user.signedIn) {
        token = user.token;
        customerName = user.profile.name;
    } else {
        token = null;
        customerName = null;
    }
});

async function authedRequest(url, opts = {}, defaultFn = () => null) {
    opts.headers = opts.headers || {};
    opts.headers.Authorization = `Bearer ${token}`;
    const result = await fetch(url, opts);
    if (result.status === 401) {
        signOut();
        return defaultFn();
    }
    try {
        return await result.json();
    } catch {
        return defaultFn();
    }
}

export default {
    async signIn(email, password) {
        try {
            const result = await fetch("/api/token", {
                method: "POST",
                body: JSON.stringify({email, password})
            });
            if (result.status === 200) {
                const detail = await result.json();
                return {...detail, success: true};
            } else if (result.status === 401) {
                return {success: false, error: "Invalid username / password combination"};
            } else {
                return {success: false, error: "Unable to sign in right now. Please try again later"};
            }
        } catch {
            return {success: false, error: "Unable to sign in right now. Please try again later"};
        }
    },
    async reset(email) {
        try {
            const result = await fetch("/api/reset", {
                method: "POST",
                body: JSON.stringify({email})
            });
            if (result.status === 200) {
                return {success: true};
            } else {
                return {success: false, error: "Unable to reset your account right now. Please try again later"};
            }
        } catch {
            return {success: false, error: "Unable to sign in right now. Please try again later"};
        }
    },
    async checkInvite(code) {
        try {
            const result = await fetch(`/api/invitation/${code}`);
            if (result.status === 200) {
                return await result.json();
            } else {
                return "NotFound";
            }
        } catch {
            return "NotFound";
        }
    },
    async redeemInvite(code, password) {
        try {
            const result = await fetch(`/api/invitation/${code}`, {method: "POST", body: JSON.stringify({password})});
            if (result.status === 200) {
                const detail = await result.json();
                return {...detail, success: true};
            } else {
                return {success: false};
            }
        } catch {
            return {success: false};
        }
    },
    async eSignByCode(documentCode, signerCode) {
        try {
            const result = await fetch(`/api/e-sign/${documentCode}/${signerCode}`);
            if (result.status === 200) {
                const redirect = await result.json();
                return {redirect, success: true};
            } else {
                let error;
                try {
                    const detail = await result.json();
                    error = detail.error || "Unexpected error";
                } catch {
                    error = "Unexpected error";
                }
                return {success: false, error};
            }
        } catch {
            return {success: false, error: "Unexpected error"};
        }
    },
    welcome() {
        return authedRequest("/api/quote");
    },
    documents(outbound) {
        return authedRequest(`/api/documents/${outbound ? "outbound" : "inbound"}`, {}, () => []);
    },
    pendingESigns() {
        return authedRequest(`/api/documents/e-sign`, {}, () => []);
    },
    proceedToSign(packId) {
        return authedRequest(`/api/documents/e-sign/${packId}`, {method: "POST"});
    },
    upload(file, name, progress) {
        return new Promise(resolve => {
            const xhr = new XMLHttpRequest();
            xhr.upload.addEventListener("loadstart", progress);
            xhr.upload.addEventListener("progress", progress);
            xhr.upload.addEventListener("load", progress);
            xhr.upload.addEventListener("error", progress);
            xhr.addEventListener("load", async () => {
                if (xhr.status === 200) {
                    resolve(JSON.parse(xhr.responseText));
                    return;
                } else if (xhr.status === 401) {
                    signOut();
                }
                resolve(false);
            });
            xhr.addEventListener("error", () => resolve(false));
            xhr.open("POST", "/api/documents", true);
            xhr.setRequestHeader("Authorization", `Bearer ${token}`);
            xhr.setRequestHeader("Content-Type", file.type);
            xhr.setRequestHeader("X-File-Name", name || file.name);
            xhr.setRequestHeader("X-Customer-Name", customerName);
            xhr.send(file);
        });
    }
}